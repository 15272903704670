import * as React from "react";
import {
  CreateButton,
  EditButton,
  Labeled,
  NumberField,
  RecordContextProvider,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Title,
  Toolbar,
  useDataProvider,
  useNotify,
  FunctionField,
} from "react-admin";
import { Box, Card, Divider } from "@mui/material";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import { YMDHMDateField } from "../Common";
import { calcBanState } from "../UserBan";
import { Typography } from "@mui/material";
import { Platforms } from "../../config/platform";

const UserSearchToolbar = (props: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { getValues } = useFormContext();

  const { mutate, isLoading } = useMutation(
    ["getOne"],
    () => {
      return dataProvider.getOne("User", { id: getValues("UserId") });
    },
    {
      onSuccess: (d: any) => {
        props.setData(d.data);
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );
  return (
    <Toolbar>
      <SaveButton label="検索" onClick={() => mutate()} disabled={isLoading} />
    </Toolbar>
  );
};

const UserShowData = (props: any) => {
  if (!props.data.id) {
    return <div></div>;
  }
  return (
    <Card sx={{ mt: 2 }}>
      <RecordContextProvider value={props.data}>
        <SimpleShowLayout divider={<Divider />}>
          <TextField label="ユーザーID" source="id" />
          <TextField label="FireBaseUID" source="UserAccount.FirebaseUid" />
          <TextField
            label="デバイスセッションID"
            source="UserAccount.DeviceSessionId"
          />
          <FunctionField
            label="プラットフォーム"
            render={(record: any) =>
              Platforms.find(
                (platform) => platform.id === record.UserAccount.Platform
              )?.label || ""
            }
          />
          <TextField label="ユーザーネーム" source="UserProfile.Name" />
          <TextField label="コメント" source="UserProfile.Comment" />
          <NumberField
            label="お気に入りキャラカードID"
            source="UserProfile.FavoriteCharacterCardId"
          />
          <BirthdayField label="誕生日" data={props.data.UserBirthday} />
          <NumberField label="ランク" source="UserRank.Rank" />
          <NumberField label="経験値" source="UserRank.Exp" />
          <NumberField label="AP" source="UserStamina.Stamina" />
          <YMDHMDateField
            label="AP更新日時"
            source="UserStamina.LastUpdatedAt"
          />
          <NumberField label="マネー" source="UserMoney.Money" />
          <TextField label="無償ジュエル" source="UserStone.Count" />
          <TextField label="有償ジュエル" source="UserStone.BilledCount" />
          <BirthMonthField
            label="年齢確認生年月日"
            data={props.data.UserBirthMonth}
          />
          <YMDHMDateField label="作成日時" source="User.CreatedAt" />
          <YMDHMDateField label="最終ログイン日時" source="User.LastLoginAt" />
          <UserBanField data={props.data} />
        </SimpleShowLayout>
      </RecordContextProvider>
    </Card>
  );
};

const UserBanField = (props: any) => {
  return (
    <>
      <Labeled label="Ban状況">
        <TextField
          record={{ BanState: calcBanState(props.data.UserBan) }}
          source="BanState"
        />
      </Labeled>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          bgcolor: "background.paper",
          color: "text.secondary",
          "& hr": {
            mx: 0.5,
          },
        }}
      >
        {(() => {
          if (!props.data.UserBan) {
            return (
              <CreateButton
                to={{
                  pathname: "/UserBan/create",
                  state: { record: { UserId: props.data.id } },
                }}
              />
            );
          } else {
            return <EditButton resource="UserBan" />;
          }
        })()}
      </Box>
    </>
  );
};

export const UserSearch = (props: any) => {
  return (
    <Card>
      <Title title="ユーザー情報" />
      <SimpleForm toolbar={<UserSearchToolbar setData={props.setData} />}>
        <TextInput label="UserId" source="UserId" />
      </SimpleForm>
    </Card>
  );
};

export const UserShow = (props: any) => {
  const [data, setData] = React.useState({});
  return (
    <div>
      <UserSearch setData={setData} />
      <UserShowData data={data} />
    </div>
  );
};

const BirthdayField = (props: any) => {
  const day = props.data?.Day;
  const month = props.data?.Month;

  if (day == null || month == null) return null;

  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  return (
    <Typography
      sx={{ "font-size": 14 }}
    >{`${formattedMonth}/${formattedDay}`}</Typography>
  );
};

const BirthMonthField = (props: any) => {
  const day = props.data?.Day;
  const month = props.data?.Month;
  const year = props.data?.Year;

  if (day == null || month == null || year == null) return null;

  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  return (
    <Typography
      sx={{ "font-size": 14 }}
    >{`${year}/${formattedMonth}/${formattedDay}`}</Typography>
  );
};
