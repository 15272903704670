export const FormatDate = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  return date.toISOString();
};

export const CurrentDate = () => {
  return new Date().toISOString();
};
