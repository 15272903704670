import {
  Datagrid,
  NumberField,
  NumberInput,
  TextField,
  ReferenceField,
} from "react-admin";
import { CommonList } from "../Common";

const userShopMerchandiseFilters = [
  <NumberInput source="ShopMerchandiseId" label="ShopMerchandiseId" />,
  <NumberInput source="PurchaseCount" label="購入数" />,
  <NumberInput source="TotalPurchaseCount" label="総購入数" />,
];

export const UserShopMerchandiseList = (props: any) => (
  <CommonList {...props} addFilters={userShopMerchandiseFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="ShopMerchandiseId" label="ShopMerchandiseId" />
      <ReferenceField
        source="ShopMerchandiseId"
        reference="ShopMerchandise"
        label="商品名"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="PurchaseCount" label="購入数" />
      <NumberField source="TotalPurchaseCount" label="総購入数" />
      <TextField source="LastPurchasedAt" label="最終購入日時" />
    </Datagrid>
  </CommonList>
);
