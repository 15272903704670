import * as React from "react";
import { Card, Toolbar, CardContent } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Title,
  useDataProvider,
  useNotify,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { commands } from "./commands";
import { UserList } from "./UserList";
import { ResultCard } from "./ResultCard";

const findByCommandName = (name: string) => {
  return commands.find((element) => element.name === name);
};

const DebugEditToolbar = ({ setData, ...props }: any) => {
  const dataProvider = useDataProvider();
  const { getValues } = useFormContext();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation(
    ["debug"],
    () => {
      const command = findByCommandName(getValues("command"));
      if (command) {
        return dataProvider.debug(
          command.api,
          command.method,
          ConvertParams(getValues("params"), getValues("paramConverters"))
        );
      }
    },
    {
      onSuccess: (data: any) => {
        setData(data.data);
        notify("SUCCESS", { type: "success" });
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar {...props}>
      <SaveButton
        label="実行"
        icon={<ArrowRightIcon />}
        onClick={() => mutate()}
        disabled={isLoading}
      />
    </Toolbar>
  );
};

const DebugCommandSelect = ({ setDesc, setResult }: any) => {
  const { setValue, getValues } = useFormContext();

  const changeSelect = (data: any) => {
    const command = findByCommandName(data.target.value);
    if (command) {
      let params = JSON.parse(command.params);
      params.UserId = getValues("fixedUserId") || params.UserId;
      setValue("params", JSON.stringify(params, null, 2));
      setValue("paramConverters", command.paramConverters);
      setDesc(command.desc);
      setResult(command.result);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SelectInput
        onChange={changeSelect}
        source="command"
        optionValue="name"
        optionText="name"
        choices={commands}
        validate={[required()]}
        style={{ marginRight: "10px" }}
      />
      <TextInput source="fixedUserId" label="ユーザーID固定" />
    </div>
  );
};

export const Debug = (props: any) => {
  const [desc, setDesc] = React.useState("");
  const [result, setResult] = React.useState(null);
  const [data, setData] = React.useState(null);

  return (
    <div>
      <Card {...props}>
        <Title title="Debug" />
        <SimpleForm toolbar={<DebugEditToolbar setData={setData} />}>
          <DebugCommandSelect setDesc={setDesc} setResult={setResult} />
          {desc !== "" && (
            <Card>
              <CardContent>
                {desc.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </CardContent>
            </Card>
          )}
          <TextInput source="params" multiline fullWidth />
        </SimpleForm>
      </Card>
      {ResultFunc(result, data)}
    </div>
  );
};

interface ResultComponents {
  UserList: any;
  ResultCard: any;
}

const resultComponents = {
  UserList: UserList,
  ResultCard: ResultCard,
} as ResultComponents;

function ResultFunc(result: any, data: any) {
  if (result == null || data == null) {
    return <></>;
  }

  const r: keyof ResultComponents = result;
  const Result = resultComponents[r];
  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>結果</CardContent>
      <Result data={data} />
    </Card>
  );
}

function ConvertParams(params: string, paramConverters: string) {
  if (paramConverters == null) {
    return params;
  }

  let convertedParams = JSON.parse(params);
  const pcs = JSON.parse(paramConverters);
  Object.entries(pcs).forEach(function ([key, converter]) {
    if (!(key in convertedParams)) {
      return;
    }

    switch (converter) {
      case "ToUTC":
        const d = new Date(convertedParams[key]);
        convertedParams[key] = d.toJSON();
        break;
      default:
        alert("invalid converter: " + converter);
        break;
    }
  });

  return JSON.stringify(convertedParams);
}
