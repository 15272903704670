import {
  BooleanField,
  Datagrid,
  NumberField,
  NumberInput,
  TextField,
  BooleanInput,
  ReferenceField,
} from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const userCharacterCardFilters = [
  <NumberInput source="CharacterCardId" label="CharacterCardId" />,
  <NumberInput source="Exp" label="EXP" />,
  <BooleanInput source="IsFavorite" label="お気に入り" />,
  <BooleanInput source="IsChangeImage" label="イラスト変更" />,
];

export const UserCharacterCardList = (props: any) => (
  <CommonList {...props} addFilters={userCharacterCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumberField source="CharacterCardId" label="CharacterCardId" />
      <ReferenceField
        source="CharacterCardId"
        reference="CharacterCard"
        label="カード名"
      >
        <TextField source="card_name" />
      </ReferenceField>
      <NumberField source="Exp" label="EXP" />
      <BooleanField source="IsFavorite" label="お気に入り" />
      <BooleanField source="IsChangeImage" label="イラスト変更" />
      <YMDHMSDateField source="AcquiredAt" label="獲得日時" />
    </Datagrid>
  </CommonList>
);
