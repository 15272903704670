import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
} from "react-admin";
import {
  CommonList,
  DateTimeWithSecInput,
  EditToolbar,
  EditTitle,
  YMDHMSDateField,
} from "../Common";

export const GlobalPresentList = (props: any) => (
  <CommonList {...props} filters={null}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="PresentId" label="ID" />
      <NumberField source="ContentType" label="コンテンツタイプ" />
      <NumberField source="ContentId" label="コンテンツID" />
      <NumberField source="ContentNum" label="個数" />
      <YMDHMSDateField source="OpenAt" label="付与開始日時" />
      <YMDHMSDateField source="ExpiredAt" label="受取期限" />
      <EditButton />
    </Datagrid>
  </CommonList>
);

export const GlobalPresentEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="全体付与" />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <NumberInput source="ContentType" label="コンテンツタイプ" />
      <NumberInput source="ContentId" label="コンテンツID" />
      <NumberInput source="ContentNum" label="個数" />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" />
      <DateTimeWithSecInput source="ExpiredAt" label="受取期限" />
    </SimpleForm>
  </Edit>
);

export const GlobalPresentCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <NumberInput source="ContentType" label="コンテンツタイプ" />
      <NumberInput source="ContentId" label="コンテンツID" />
      <NumberInput source="ContentNum" label="個数" />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" />
      <DateTimeWithSecInput source="ExpiredAt" label="受取期限" />
    </SimpleForm>
  </Create>
);
