import { useDataProvider, SelectInput, GetListResult, GetListParams } from "react-admin";
import { useMemo, useState } from "react";

const defaultListParam: GetListParams = {
  pagination: {
    page: 1,
    perPage: 1000,
  },
  sort: {
    field: "",
    order: "DESC",
  },
  filter: undefined,
};

type GachaGroupListCustomParam = {
  openTime: Date;
};

type GachaGroup = {
  id: string;
  title: string;
  image: string;
  priority: number;
  point_item_id: number;
  gacha_stamp_id: number;
  open_at: Date;
  close_at: Date;
};

type GachaGroupListDropDownProps = {
  openTime: Date | undefined;
  setSelectedGachaGroupID: React.Dispatch<React.SetStateAction<number>>;
};

export const GachaGroupListDropDown = ({ openTime, setSelectedGachaGroupID }: GachaGroupListDropDownProps) => {
  const dataProvider = useDataProvider();
  const [gachaGroupListResult, setGachaGroupListResult] = useState<GetListResult<GachaGroup>>();

  useMemo(() => {
    (async () => {
      if (!openTime || Number.isNaN(openTime.getTime())) {
        setGachaGroupListResult(undefined);
        return;
      }

      const gachaGroupListCustomParam: GachaGroupListCustomParam = {
        openTime: openTime,
      };

      defaultListParam.meta = gachaGroupListCustomParam;
      setGachaGroupListResult(await dataProvider.getList<GachaGroup>("GachaGroup", defaultListParam));
    })();
  }, [openTime]);

  return (
    <>
      {gachaGroupListResult && (
        <SelectInput
          source="gacha_group_list"
          choices={gachaGroupListResult.data}
          optionText="title"
          isLoading={gachaGroupListResult ? false : true}
          onChange={(selected) => {
            setSelectedGachaGroupID(selected.target.value);
          }}
        />
      )}
    </>
  );
};
