import { useState, useEffect, useLayoutEffect } from "react";
import {
  Toolbar,
  SaveButton,
  Create,
  Edit,
  EditButton,
  Datagrid,
  SimpleForm,
  TextField,
  DateField,
  TextInput,
  DateTimeInput,
  DeleteWithConfirmButton,
  useDataProvider,
  useNotify,
  useGetOne,
  useRecordContext,
  ImageInput,
  ImageField,
} from "react-admin";
import { EditTitle } from "../Common";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import { HTMLPreviewRawText, AnnouncementBuilderComponent } from "./htmlComponent";
import { AnnouncementTemplateLoader } from "./templateLoader";
import { CommonList } from "../Common";

export const AnnouncementList = (props: any) => (
  <CommonList {...props} filters={null}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="Id" />
      <TextField source="Title" label="Title" />
      <TextField source="Path" label="Path" />
      <DateField source="OpenAt" label="OpenAt" />
      <DateField source="CloseAt" label="CloseAt" />
      <TextField source="Category" label="Category" />
      <TextField source="Tag" label="Tag" />
      <EditButton />
    </Datagrid>
  </CommonList>
);

const HTMLCreateToolbar = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { getValues } = useFormContext();

  const { mutate, isLoading } = useMutation(
    ["create"],
    () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      return dataProvider.create("Announcement", {
        data: {
          id: getValues("Id"),
          title: getValues("Title"),
          html: docType + html,
          openAt: getValues("OpenAt"),
          closeAt: getValues("CloseAt"),
          category: getValues("Category"),
          tag: getValues("Tag"),
          banner: getValues("Banner"),
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton label="作成" onClick={() => mutate()} disabled={isLoading} />
    </Toolbar>
  );
};

const announcementTemplateBucketPath = process.env.REACT_APP_ANNOUNCEMENT_TEMPLATE_BUCKET_URL;

export const AnnouncementCreate = () => {
  const [html, setHTML] = useState("");

  useLayoutEffect(() => {
    (async () => {
      const h = await AnnouncementTemplateLoader.load(`${announcementTemplateBucketPath!}/index.html`);
      const replaced = AnnouncementTemplateLoader.replaceURL(h, announcementTemplateBucketPath!);
      setHTML(replaced);
    })();
  }, []);

  return (
    <Create>
      <SimpleForm toolbar={<HTMLCreateToolbar />}>
        <AnnouncementBuilderComponent />
        <TextInput source="Id" helperText="指定しない場合はランダムな値が設定" />
        <TextInput source="Title" isRequired />
        <TextInput source="Body" isRequired />
        <ImageInput source="Banner" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="Category" isRequired />
        <TextInput source="Tag" isRequired />
        <DateTimeInput source="OpenAt" isRequired />
        <DateTimeInput source="CloseAt" isRequired />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Create>
  );
};

const announcementBucket = process.env.REACT_APP_ANNOUNCEMENT_BUCKET_URL;

const AnnouncementUpdateToolbar = (props: any) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { data: previous, isLoading: isGetOneLoading } = useGetOne("Announcement", { id: record.id });
  const { getValues } = useFormContext();

  useEffect(() => {
    if (isGetOneLoading) return;
    (async () => {
      const h = await AnnouncementTemplateLoader.load(`${announcementBucket}/${previous.Path}`);
      props.setHTML(h);
    })();
  }, [isGetOneLoading]);

  const { mutate, isLoading } = useMutation(
    ["update"],
    () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      return dataProvider.update("Announcement", {
        id: record.id,
        previousData: {
          title: previous.title,
          openAt: previous.openAt,
          closeAt: previous.closeAt,
          category: previous.category,
          tag: previous.tag,
        },
        data: {
          title: getValues("Title"),
          html: docType + html,
          openAt: getValues("OpenAt"),
          closeAt: getValues("CloseAt"),
          category: getValues("Category"),
          tag: getValues("Tag"),
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton label="作成" onClick={() => mutate()} disabled={isGetOneLoading || isLoading} />
      <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" translateOptions={{ name: record.id }} />
    </Toolbar>
  );
};

export const AnnouncementEdit = (props: any) => {
  const [html, setHTML] = useState("");

  return (
    <Edit {...props} title={<EditTitle name="Announcement" />}>
      <SimpleForm toolbar={<AnnouncementUpdateToolbar setHTML={setHTML} />}>
        <AnnouncementBuilderComponent />
        <TextInput source="Title" />
        <DateTimeInput source="OpenAt" />
        <DateTimeInput source="CloseAt" />
        <TextInput source="Category" />
        <TextInput source="Tag" />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Edit>
  );
};
