import { Datagrid, NumberField, NumberInput, TextField } from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const userGachaFilters = [
  <NumberInput source="GachaId" label="GachaId" />,
  <NumberInput source="PurchaseCount" label="現在の購入数" />,
  <NumberInput source="TotalPurchaseCount" label="総購入数" />,
];

export const UserGachaList = (props: any) => (
  <CommonList {...props} addFilters={userGachaFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="UserId" />
      <NumberField source="GachaId" label="GachaId" />
      <NumberField source="PurchaseCount" label="現在の購入数" />
      <NumberField source="TotalPurchaseCount" label="総購入数" />
      <YMDHMSDateField source="LastPurchasedAt" label="最終購入日時" />
    </Datagrid>
  </CommonList>
);
