import { List, TextInput } from "react-admin";
import { CommonPagination } from "./index";

export const CommonList = (props: any) => {
  let filters = [<TextInput source="UserId" label="ユーザーID" alwaysOn />];

  if (props.filters === null) {
    filters = [];
  } else if (props.filters) {
    filters = props.filters;
  } else if (props.addFilters) {
    filters.push(...props.addFilters);
  }

  return (
    <List
      {...props}
      empty={false}
      perPage={100}
      pagination={<CommonPagination />}
      filters={filters}
    />
  );
};
